@font-face {
  font-family: DINPro;
  src: url(./fonts/DINPro-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: DINPro;
  src: url(./fonts/DINPro-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: DINPro;
  src: url(./fonts/DINPro-Medium.otf);
  font-weight: 700;
}

@font-face {
  font-family: DINPro;
  src: url(./fonts/DINPro-Medium.otf);
  font-weight: bold;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* flex居左布局 */
.flex-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-start;
}

/* flex居右布局 */
.flex-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}

/* flex两端布局 */
.space-between {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;

  /* Safari 6.1+ */
  flex-wrap: wrap;
  justify-content: space-between;
}

/* flex居中布局 */
.space-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

/* flex围绕布局 */
.space-around {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
}

/* flex居左垂直居中布局 */
.flex-start-align {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* flex居两端垂直居中布局 */
.space-between-align {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* flex居右垂直居中布局 */
.flex-start-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* flex居两端布局-垂直底部 */
.space-between-align-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

/* flex居两端布局-中轴线 */
.align-items-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

/* flex垂直布局 */
.flex-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

/* flex居中 */
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* flex垂直布局上 */
.flex-content-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

/* flex垂直布局局居中 */
.flex-content-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}

/* flex垂直布局局居中 */
.flex-content-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
}


.App {
  width: 100%;
  height: 100%;
  font-family: DINPro, "RC TYPE", Roboto, Avenir, Helvetica, Arial, sans-serif;
  color: var(--primary-font-color);
}



// @font-face {
//   font-family: Avenir Next W02;
//   src: url(./fonts/AvenirNext-Regular.woff2) format("woff2"), url(./fonts/AvenirNext-Regular.woff) format("woff")
// }

// @font-face {
//   font-family: Avenir Next W02;
//   font-style: italic;
//   src: url(./fonts/AvenirNext-Regular-Italic.woff2) format("woff2"), url(./fonts/AvenirNext-Regular-Italic.woff) format("woff")
// }

// @font-face {
//   font-family: Avenir Next W02;
//   src: url(./fonts/AvenirNext-Medium.woff2) format("woff2"), url(./fonts/AvenirNext-Medium.woff) format("woff");
//   font-weight: 500
// }

// @font-face {
//   font-family: Avenir Next W02;
//   src: url(./fonts/AvenirNext-Demi.woff2) format("woff2"), url(./fonts/AvenirNext-Demi.woff) format("woff");
//   font-weight: 600
// }

// @font-face {
//   font-family: Avenir Next W02;
//   font-style: italic;
//   src: url(./fonts/AvenirNext-Demi-Italic.woff2) format("woff2"), url(./fonts/AvenirNext-Demi-Italic.woff) format("woff");
//   font-weight: 600
// }

// @font-face {
//   font-family: Avenir Next W02;
//   src: url(./fonts/AvenirNext-Bold.woff2) format("woff2"), url(./fonts/AvenirNext-Bold.woff) format("woff");
//   font-weight: 700
// }

// @font-face {
//   font-family: Nunito;
//   src: url(./fonts/Nunito-Regular.ttf) format("ttf")
// }

// body {
//   font-family: Avenir Next W02, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
//   font-weight: 400;
//   font-size: 14px;
//   color: #33475b;
//   background-color: #f0f2f5;
// }

// h1, h2, h3 {
//   font-family: Avenir Next W02, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// a {
//   font-family: Avenir Next W02, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

input {
  background-color: #fff;
  box-shadow: inset 0 0 0 1000px #fff !important;
}

b {
  font-weight: 500;
  color: var(--primary-label-color) !important;
}

.scrollbar,
.ant-drawer-body {

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.1);
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0;
    background: rgba(0, 4, 40, 0.06);
  }
}

.generate-margin-padding(@n, @i: 1) when (@i =< @n) {
  .vmargin-level-@{i} {
    margin-top: (@i * 5px);
    margin-bottom: (@i * 5px);
  }

  .hmargin-level-@{i} {
    margin-left: (@i * 5px);
    margin-right: (@i * 5px);
  }

  .vpadding-level-@{i} {
    padding-top: (@i * 5px);
    padding-bottom: (@i * 5px);
  }

  .hpadding-level-@{i} {
    padding-left: (@i * 5px);
    padding-right: (@i * 5px);
  }

  .generate-margin-padding(@n, (@i + 1));
}

.generate-margin-padding(4);


.full{
  width: 100%;
  height: 100%;
}
.bg-primary {
  background-color: @primary-color;
}

.bg-head-menu {
  background-color: #fff;
}

.bg-page {
  background-color: rgb(237, 240, 255);
}

.bg-white {
  background-color: #fff;
}

.bg-lightgray {
  background-color: rgb(251, 251, 251);
}

.align-item-left {
  text-align: left;
}

.align-item-center {
  text-align: center;
}

.align-item-right {
  text-align: right;
}

.flex {
  display: flex;

  &.flex-column {
    flex-direction: column;
  }

  &.flex-row-reverse {
    flex-direction: row-reverse;
  }

  &.flex-column-reverse {
    flex-direction: column-reverse;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.center {
    justify-content: center;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-end {
    align-items: flex-end;
  }

  &.align-center {
    align-items: center;
  }

  .flex-item {
    flex-grow: 0;

    &.flex-main {
      flex-grow: 1;
    }

    &.noshrink {
      flex-shrink: 0;
    }
  }
}

.word {

  &.primary {
    color: @primary-color;
  }

  &.cont {
    color: #666;
  }

  &.tip {
    color: #999;
  }

  &.white {
    color: #fff;
  }

  &.bold {
    font-weight: 500;
  }

  &.big {
    font-size: 16px;
  }

  &.small {
    font-size: 12px;
  }

  &.large {
    font-size: 18px;
  }

  &.size20 {
    font-size: 20px;
  }

  &.size22 {
    font-size: 22px;
  }

  &.size24 {
    font-size: 24px;
  }

  &.weight {
    font-weight: 600;
  }
}

.pointer {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.antd-icon {
  color: #e2001a !important;
  font-size: 20px !important
}

.antd-icon2 {
  font-size: 20px !important
}

.normal-icon {
  color: @primary-color !important;
  font-size: 20px;
}

.page-center-spin {
  max-height: none !important;

  .ant-spin-dot {
    top: 50% !important;
    margin-left: -45px !important;
    margin-top: -45px !important;
  }
}

.underline-button {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  border-bottom: 1px solid #ccc;
  height: auto;
  line-height: 1.2;
  border-radius: 0;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
    background-color: unset;
  }
}

.underline-icon-button {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  cursor: pointer;
  color: #444;
  font-weight: 500;

  &:hover, &:focus {
    color: var(--primary-color);
  }

  .anticon {
    font-size: 20px;
  }

  &.primary .anticon {
    color: var(--primary-color);
  }

  span:not(.anticon) {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border-top: 1px solid var(--primary-border-color);
    }
  }
}

.VIcon > svg path {
  fill: currentColor;
}

.rc-desktop-modal {
  width: auto !important;
  min-width: 360px;
  max-width: 800px !important;
}

.table-vertical-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  gap: 4px;

  &:hover {
    color: var(--primary-color);
  }

  span {
    font-size: 20px;
  }

  p {
    transform: scale(.83);
    margin-bottom: 0;
  }
}

.gray-ant-tooltip {
  div.ant-tooltip-arrow {
    left: -6.586291px;
  }

  span.ant-tooltip-arrow-content {
    box-shadow: none;
    background-color: #f6f6f6;
    border: 1px solid var(--primary-border-color);
    overflow: hidden;
  }

  div.ant-tooltip-inner {
    box-shadow: none;
    border: 1px solid var(--primary-border-color);
    background-color: #f6f6f6;
    color: var(--primary-font-color);
    padding: 10px;
    text-align: center;
  }
}

div.dropdown-select-item {
  border-bottom: 1px solid var(--primary-border-color);
  color: var(--primary-color);
}

.global-descriptions {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 40px;

  .ant-typography {
    color: var(--primary-color);
    font-weight: 400;
  }

  .ant-descriptions-item-content {
    color: var(--primary-label-color);
    font-size: 16px;
  }

  .ant-divider-horizontal {
    border-top: 1px solid var(--primary-border-color);
    margin: 10px 0;
  }

  .ant-descriptions-item-label {
    font-size: 16px;
    font-weight: 700;
    width: 300px;
  }

  .ant-descriptions-item-label::after {
    content: "";
  }
}

.text-gray-400 {
  color: var(--primary-border-color);
}

.text-white {
  color: #fff;
}

div.ant-alert-success {
  background-color: rgba(0, 137, 0, 0.15);
  border: 1px solid #008900;

  .ant-alert-message {
    color: #008900;
  }

  .ant-alert-close-icon .anticon-close {
    color: #008900;
  }
}

.select-no-style {
  padding-left: 0 !important;
  max-width: 100%;

  .ant-select-selector {
    padding-left: 0 !important;
  }
}

.pbottom-20 {
  padding-bottom: 20px;
}

.room8 {
  zoom: 0.8;
  min-height: 80vh;
}