.login-callback {
  width: 100%;
  height: 100vh;

  .login-callback-spin {
    position: absolute;
    left: 50% !important;
    top: 50% !important;
    margin-left: -45px !important;
    margin-top: -45px !important;
  }
}
@primary-color: #e2001a;@border-radius-base: 4px;