// 直接使用var使用变量
// 需要添加到项目中的变量直接添加到root中
// 如需修改antd变量 在craco.config.js中修改
// 不要也不需要手动引入style.less文件来引入变量
// https://developer.mozilla.org/zh-CN/docs/Web/CSS/var()

:root {
  --primary-color: @primary-color;
  --head-color: #fff;
  --site-background-color: var(--primary-background-color);
  --site-background-dark-color: @primary-7;
  --input-hover-border-color: @input-hover-border-color;
  --border-radius-base: @border-radius-base;
  --primary-border-color: #d7d7d7;
  --primary-background-color: #f6f6f6;
  --primary-font-color: #444444;
  --primary-label-color: #666666;
  --primary-box-shadow:0 5px 15px rgba(0, 0, 0, 0.11);
  --primary-border-radius: 10px;
  --status-green-color: #008900;
  --status-red-color: #c03344;
  --status-yellow-color: #ee8b00;
  --status-gray-color: #d7d7d7;
  --status-black-color: #333333;

}
