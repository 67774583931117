/*
重置antd的某些样式，可以使用配置的尽量在craco.config.js的 modifyVars中修改，无法使用配置的覆盖在这里
*/
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

// Select框Rules星星标志样式修改
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: " " !important;
  display: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #666;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.ant-checkbox-inner {
  border-radius: 2px !important;
}

.ant-btn-background-ghost.ant-btn-primary {
  font-weight: 500;
  border-width: 2px;
}

.ant-form-item-label > label,
.ant-pro-form-group-title {
  color: var(--primary-label-color) !important;
  font-weight: 500 !important;
}

span.ant-descriptions-item-label {
  color: var(--primary-label-color);
  font-weight: 500;
  font-size: 16px;
}
span.ant-descriptions-item-content {
  color: var(--primary-label-color);
  font-size: 16px;
  text-align: left;
}

.antd-result-black-icon {
  height: 70px;
  color: var(--primary-font-color);
  font-size: 80px;
  line-height: 1;
}

.ant-table-container {
  overflow: hidden;
  border: 1px solid var(--primary-background-color);
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.ant-table table {
  border-radius: 10px 10px 0 0 !important;

  td {
    color: var(--primary-label-color);
    font-size: 14px;
  }
}

.ant-table .ant-table-thead > tr > th {
  color: #fff;
  font-size: 14px;
  background-color: #444;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 10px !important;
}

.ant-table .ant-table-thead th.ant-table-column-has-sorters:hover {
  color: #fff;
  background-color: #444;
}

.ant-table-row:nth-child(odd) {
  td {
    background-color: var(--primary-background-color);
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: var(--primary-background-color);
  }
}

div.ant-modal-header {
  border-radius: 10px 10px 0 0;
}

div.ant-modal-content {
  border-radius: 10px;
}

button.ant-btn-round {
  padding: 4px 26px;
}

button.ant-btn-text {
  &:focus,
  &:hover {
    color: var(--primary-color);
    background-color: transparent;
  }
}

div.ant-tabs-tab {
  color: #808285;
  font-weight: 400;
  font-size: 18px;
}

div.ant-tabs-tab-active {
  font-weight: 500;
}

div.ant-descriptions-title {
  color: var(--primary-color);
}

div.ant-form-item-label > label::after {
  content: "";
}

span.ant-select-selection-placeholder {
  z-index: 1;
}
