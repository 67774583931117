.App .ant-layout-sider {
  height: calc(100vh - 64px);
}

.App .site-layout {
  position: relative;
  height: calc(100vh - 64px);
  padding: 10px 15px;
  background-color: var(--site-background-color);
  overflow-y: auto;
  overflow-x: hidden;
}

.content-container {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 16px 0 rgba(235, 235, 235, 0.69);
}

#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.main-layout .ant-layout-sider {
  background-color: #fff;
}

.ant-layout-header.main-header {
  height: 50px;
  padding: 0 0;
  line-height: unset;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  overflow: hidden;
  z-index: 2;
  .user-info {
    font-size: 16px;
    position: relative;
    cursor: pointer;
    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      right: -8px;
      margin-top: -2px;
      border-width: 8px 6px;
      border-style: solid;
      border-color: rgb(140,144,155) transparent transparent transparent;
    }
  }
  .head-avatar {
    background-color: #fff;
    border: 1px solid #333;
    color: var(--primary-color);
  }
}

.ant-menu.main-left-menu {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  border-right: 0;
  padding: 30px 20px;
  font-size: 14px;
  > .ant-menu-item,
  > .ant-menu-submenu .ant-menu-submenu-title {
    height: 50px;
    line-height: 50px;
    padding-right: 10px;
  }
  > .ant-menu-submenu,
  > .ant-menu-item {
    margin-bottom: 20px !important;
    &.ant-menu-submenu-open .ant-menu-submenu-title,
    &.ant-menu-submenu-selected .ant-menu-submenu-title,
    &.ant-menu-item-selected {
      background-color: var(--primary-color);
      border-radius: 5px;
      color: #fff;
      a {
        color: #fff;
      }
    }
    .ant-menu-submenu-arrow {
      display: none;
    }
    .ant-menu-sub.ant-menu-inline {
      margin-top: 10px;
      background-color: rgb(237,240,255);
      border-radius: 5px;
      .ant-menu-item::before {
        content: '';
        position: absolute;
        left: 30px;
        top: 50%;
        margin-top: -3px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        border: 1px solid #999;
      }
      .ant-menu-item-selected {
        background-color: rgb(237,240,255);
        &::after {
          display: none;
        }
        &::before {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
        }
      }
    }
  }
  > .ant-menu-item.setting-menu {
    position: absolute;
    width: 161px;
    padding-right: 24px;
    left: 20px;
    bottom: -40px;
    text-align: center;
    border-radius: 5px;
    background-color: rgb(237,240,255);
    transition: width .2s linear;
    &.ant-menu-item-selected {
      background-color: var(--primary-color);
    }
  }
}

.ant-layout-sider-collapsed .ant-menu.main-left-menu > .ant-menu-item.setting-menu {
  width: 40px;
  padding: 0 0;
}

.head-dropdown-menu .ant-dropdown-menu-item {
  padding: 10px 20px;
}

.search-info .ant-input-affix-wrapper {
  border-color: #dcdcdc;
  border-radius: 15px;
}

.main-layout .ant-layout-sider-trigger {
  position: absolute;
  left: 20px;
  bottom: 90px;
  width: 161px !important;
  text-align: center;
  background-color: rgb(237,240,255) !important;
  border-radius: 5px;
  transition: all .2s linear;
  color: #000;
}

.main-layout .ant-layout-sider-collapsed .ant-layout-sider-trigger {
  width: 40px !important;
  .menu-trigger {
    transform: rotate(180deg);
  }
}

.top-layout .main-header {
  color: #333;
  .ant-badge {
    color: #fff;
  }
  .ant-menu {
    height: 50px;
    line-height: 50px;
    border-bottom: none;
    background-color: var(--head-color);
    font-size: 14px;
    font-weight: 500;
    color: rgba(37, 37, 37, 0.65);
    > .ant-menu-item, .ant-menu-submenu {
      margin: 0 16px;
      padding: 0 0;
    }
    .ant-menu-item::after {
      display: none;
    }
    .ant-menu-submenu .ant-menu-submenu-title {
      padding: 0 10px 0 0;
    }
    > .ant-menu-item-selected, .ant-menu-submenu-selected {
      color: #333;
    }
    .ant-menu-item,
    .ant-menu-submenu {
      color:rgba(37, 37, 37, 0.65);
      border-bottom: none;
      a {
        color: rgba(37, 37, 37, 0.65);
      }
    }
    .ant-menu-item-selected,
    .ant-menu-item:hover,
    .ant-menu-submenu:hover,
    .ant-menu-submenu-open,
    .ant-menu-submenu-active,
    .ant-menu-submenu-title:hover {
      color: #333;
      border-bottom: none;
      a {
        color: #333;
      }
    }
    .ant-menu-submenu-selected .ant-menu-submenu-title {
      color: #333;
    }
  }
  .ant-menu-horizontal .ant-menu-submenu {

    &::after {
      content: ' ';
      position: absolute;
      left: auto;
      bottom: auto;
      right: 0;
      top: 20px;
      width: 6px;
      height: 6px;
      border-top: 1px solid transparent;
      border-right: 1px solid rgba(37, 37, 37, 0.65);
      border-bottom: 1px solid rgba(37, 37, 37, 0.65);
      border-left: 1px solid transparent;
      transform: rotate(45deg);
      transition: all .2s linear;
    }
    &:hover::after {
      top: 25px;
      border-top: 1px solid transparent;
      border-right: 1px solid #333;
      border-bottom: 1px solid #333;
      border-left: 1px solid transparent;
      transform: rotate(225deg);
    }
  }
  .user-info::after {
    border-color: #333 transparent transparent transparent;
  }

  .search-bar-container {
    height: 32px;
    width: 22px;
    position: relative;
    .search-bar-trigger {
      position: absolute;
      top: 5px;
      right: 0;
      opacity: 1;
      z-index: 4;
      cursor: pointer;
      transition: all .2s linear;
    }
    .search-bar-input {
      position: absolute;
      width: 220px;
      top: 0;
      right: 0;
      opacity: 0;
      z-index: 3;
      pointer-events: none;
      transition: all .2s linear;
    }
    &.show {

      .search-bar-trigger {
        opacity: 0;
      }
      .search-bar-input {
        pointer-events: visible;
        opacity: 1;
      }
    }
  }
}

//二级菜单样式
.ant-menu-submenu-popup .ant-menu {
  font-weight: 500;
  padding: 5px 10px;
  // &::after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: -5px;
  //   left: 10px;
  //   width: 10px;
  //   height: 10px;
  //   background-color: #333;
  //   transform: rotate(45deg);
  // }
  .ant-menu-item-active, .ant-menu-item-selected {
    background-color: var(--site-background-color);
  }
}

.site-footer {
  margin-top: 30px;
  background-color: #f7f9ff;
  border-radius: 5px;
  box-shadow: 0 0 16px 0 rgba(235, 235, 235, 0.69);
}

.header-item-popover {
  top: 60px !important;
}

.ant-dropdown-menu-item.store-info-menu-selected {
  color: var(--primary-color);
}

//图片层级问题
.ant-image-preview-mask{z-index: 10002}
.ant-image-preview-wrap{z-index: 10002}

//ant 下拉菜单样式覆盖
.ant-dropdown-menu{
  .ant-dropdown-menu-item{
    &:hover{
      background-color: var(--site-background-color);
    }
  }
  .ant-dropdown-menu-submenu-title{
    &:hover{
      background-color: var(--site-background-color);
    }
  }
  .ant-dropdown-menu-item-active{
    color: var(--primary-color);
  }
}

.ant-tooltip {
  .ant-tooltip-inner, .ant-tooltip-arrow-content {
    background-color: var(--primary-color);
  }
  a{
    color:#fff
  }
}

//错误样式
.ant-modal-confirm.rc-desktop-modal {
  .ant-modal-content {
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-close-x {
    width: 46px;
    height: 46px;
    line-height: 46px;
    color: #fff;
  }
  .ant-modal-confirm-title {
    padding: 12px 15px;
    background-color: var(--primary-color);
    color: #fff;
  }
  .ant-modal-confirm-content {
    padding: 10px 15px 5px;
  }
}

@import './phone.less';
