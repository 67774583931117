@media screen and (max-width: 575px) {
  .App .ant-layout-sider {
    display: none;
  }
  .ant-modal-confirm.rc-mobile-modal {
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-confirm-body {
      padding: 0 15px;
    }
    .ant-modal-confirm-btns {
      float: none;
      margin-top: 10px;
      border-top: 1px solid #f5f5f5;
    }
  }
}
